import axios from "axios";
import ApiUrl from "../apis/ApiUrl";
import attributesApi from "../apis/attributesApi";
import { configHeader } from "./header-configure";
import { redirect } from "./redirect";
import { configHeaderwithCancel } from "./header-configure";
import { configHeaderwithId } from "./header-configure";

const url = process.env.REACT_APP_URL;

export const fetchAttribute = (id) => async (dispatch) => {
  try {
    const response = await ApiUrl.get(`/attributes?id=${id}`, configHeader());
    if (!response.data) {
      response.data = {};
    }
    dispatch({ type: "FETCH_ATTRIBUTE", response: response.data });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const fetchPossibleValuesByTypeId = (id) => async (dispatch) => {
  // Note - this response will contains "ALL" as well with id = 0 which is needed in permission dialog admin side.
  try {
    const response = await attributesApi.get(
      `/get/picklist/state/with/unspecified/value?typeid=${id}`,
      configHeader()
    );
    if (!response.data) {
      response.data = {};
    }
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

export const modifyAttribute = (data) => async (dispatch) => {
  try {
    const response = await attributesApi.put("", data, configHeader());
    if (!response) {
      response.data = {};
    }
    response.data.message = "Attribute Modification Succesful";
    dispatch({ type: "MODIFY_ATTRIBUTE", response: response.data });
    dispatch({ type: "DISPATCH_MESSAGE", response: response });

    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    } else {
      dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
      return e.response;
    }
    return e.response;
  }
};

export const deleteAttributes = (data) => async (dispatch) => {
  try {
    const response = await attributesApi.delete("", configHeaderwithId(data));
    dispatch({ type: "DELETE_ATTRIBUTES", response: data?.ids });
    dispatch({ type: "DISPATCH_MESSAGE", response: response });
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
    }
    return e.response;
  }
};

export const saveEditabilityRuleForAttribute =
  (data, id) => async (dispatch) => {
    try {
      const response = await attributesApi.post(
        `/editability?id=${id}`,
        data,
        configHeader()
      );
      if (!response.data) {
        response.data = {};
      }
      response.data.message = "Editability Rules Saved";
      dispatch({ type: "SAVE_RULE_FOR_ATTRIBUTE", response: data });
      dispatch({ type: "DISPATCH_MESSAGE", response: response });
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
      }
    }
  };

export const saveVisibilityRuleForAttribute =
  (data, id) => async (dispatch) => {
    try {
      const response = await attributesApi.post(
        `/visibility?id=${id}`,
        data,
        configHeader()
      );
      if (!response.data) {
        response.data = {};
      }
      response.data.message = "Visibility Rules Saved";
      dispatch({ type: "SAVE_RULE_FOR_ATTRIBUTE", response: data });
      dispatch({ type: "DISPATCH_MESSAGE", response: response });
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
      }
    }
  };

export const saveDefaultColumns =
  (data, editableAttributes) => async (dispatch) => {
    try {
      const response = await attributesApi.patch("", data, configHeader());
      dispatch({ type: "DEAFULT_COLUMNS", response: editableAttributes });
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const fetchStateAttributeWithoutPickList =
  (typeid) => async (dispatch) => {
    try {
      const response = await attributesApi.get(
        `/get/picklist/attribute/state/setting?typeid=${typeid}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const fetchStateAttributeWithoutPickListWithCancel =
  (typeid, controllerRef) => async (dispatch) => {
    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      const urldata =
        url +
        `/api/attributes/get/picklist/attribute/state/setting?typeid=${typeid}`;
      return axios
        .get(urldata, configHeaderwithCancel(controllerRef))
        .then(function (result) {
          return result;
        });
    } catch (e) {
      if (e?.response && e?.response?.status && e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e;
      }
    }
  };

export const fetchAttributesDataByAttributeId =
  (attrId) => async (dispatch) => {
    try {
      const response = await attributesApi.get(
        `/attributeId?id=${attrId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const isMultiValueUsedAnywhere = (attrId) => async (dispatch) => {
  try {
    const response = await attributesApi.get(
      `/multivalue/check?attributeId=${attrId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//To check whether multiple files are present on user side or not
export const isMultipleFilesPresentOnUserSide = (data) => async (dispatch) => {
  try {
    const response = await attributesApi.post(
      `/file/is/multiple`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

// export const switchingMultivalueFromSingleValue = (attrId) => async (dispatch) => {
//   try {
//     const response = await appsApi.get(
//       `/type/attribute/relation`,
//       configHeader()
//     );
//     return response;
//   } catch (e) {
//     if (e?.response?.status === 401) {
//       redirect(e, dispatch);
//     }
//     return e.response;
//   }
// };

//To get the attributes data according to Attributes Id send 0 in container and container instance Id if calling from admin side
export const fetchAttributesDataByAttributesId = (data) => async (dispatch) => {
  try {
    const response = await attributesApi.post(`/data`, data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//to get attribute data according to type ID and datatype
export const fetchAttributesDataByTypeIdAndDataType =
  (data) => async (dispatch) => {
    try {
      const response = await attributesApi.post(
        `/datatype/data`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const fetchAttributeWithPV = (id) => async (dispatch) => {
  try {
    const response = await attributesApi.get(
      `/withPicklistPossibleValues?typeId=${id}`,
      configHeader()
    );
    if (!response.data) {
      response.data = {};
    }
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//validating computation definition
export const validationForComputationDefinition =
  (data) => async (dispatch) => {
    try {
      const response = await ApiUrl.post(
        `/computational/validate`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const isMultiValueUsedAnywhereOnAdminSide =
  (data) => async (dispatch) => {
    try {
      const response = await attributesApi.post(
        `/multivalue/uses`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

//API to check If Already Uploaded Images are more than the count
export const checkUploadedImagesCount = (attrId, count) => async (dispatch) => {
  try {
    const response = await attributesApi.get(
      `/check/multiple/images?id=${attrId}&imagecount=${count}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};
