import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
/*-----------------------------------Import From Material Starts here--------------------------------------------------*/
import { Box } from "@mui/material";
/*-----------------------------------Import From Material Ends here--------------------------------------------------*/
/*-----------------------------------Import From Common Files ------------------------------------*/
import GridLoader from "../../../../Loaders/GridLoader";
/*-----------------------------------Import From Common Files------------------------------------*/
/*--------------------Import From Action Starts here--------------------------------------------------*/
import { fetchAlreadyLinkedExternalRelationshipdataByObjectId } from "../../../../components/actions/integrationApiActions";
import { DynamicGrid } from "../../../../components/formatGridNData/DynamicGrid";
/*--------------------Import From Action Ends here--------------------------------------------------*/

const Result = (props) => {
  let { appId, containerId, containerInstanceId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      props.tabData &&
      props.tabData.length > 0 &&
      props.tabValue &&
      props.tabValue != ""
    ) {
      props.setLoader(true);
      let filData = props.tabData.filter((obj) => obj.id === props.tabValue);
      if (filData && filData.length > 0) {
        let attrList = filData[0].defaultColumn;
        let defaultColumnOrder = JSON.parse(filData[0].defaultColumnOrder);
        let filteredattributes = [];
        defaultColumnOrder.map((obj) => {
          attrList.filter((res) => {
            if (res.id == obj) {
              filteredattributes.push(res);
            }
          });
        });
        filteredattributes.push({
          internalName: "suspectFlag",
          dataType: {
            sqlType: "LOGICAL",
            name: "Logical",
            key: "BOOLEAN",
          },
          name: "Suspect Flag",
          id: "suspectFlag",
        });
        filteredattributes.push({
          internalName: "attachedBy",
          dataType: {
            sqlType: null,
            name: "Users",
            key: "MULTIPLE_USERS",
          },
          name: "Attached By",
          id: "attachedBy",
        });
        props.setAttributes(filteredattributes);
        let relatedAttrList = filData[0].relatedTypeDefaultColumn;
        let relatedTypeDefaultColumnOrder = JSON.parse(
          filData[0].relatedTypeDefaultColumnOrder
        );
        let relatedFilteredattributes = [];
        relatedTypeDefaultColumnOrder.map((obj) => {
          relatedAttrList.filter((res) => {
            if (res.id == obj) {
              relatedFilteredattributes.push(res);
            }
          });
        });
        props.setRelnAttributes(relatedFilteredattributes);
        props.setTitle(filData[0].viewName);
        props.setRelationshipId(filData[0].relationshipId);
        props.setTypeName(filData[0].connectedTypeName);
        props.setTypeId(filData[0].connectedTypeId);
        props.setParentTypeId(filData[0].typeId);

        const dataTobeSend = {
          relationId: filData[0].relationshipId,
          objectId: props.objectId,
          containerId: containerId,
          containerInstanceId: containerInstanceId,
          typeId: parseInt(filData[0].typeId),
        };
        dispatch(
          fetchAlreadyLinkedExternalRelationshipdataByObjectId(dataTobeSend)
        ).then((result) => {
          if (result !== undefined && result !== null && result) {
            if (
              result.status !== undefined &&
              result.status !== null &&
              result.status &&
              result.status === 200
            ) {
              if (result.data) {
                props.setData(result.data);
              }
              props.setLoader(false);
            } else {
              props.setLoader(false);
            }
          }
        });
      }
    }
  }, [props.tabData, props.tabValue]);

  return (
    <Box style={{ width: props.visibleSidebar ? "70%" : "100%" }}>
      <div className="col-md-9 e-bigger e-adaptive-demo">
        <div className="e-mobile-layout">
          <div className="e-mobile-content">
            {props.loader ? (
              <GridLoader />
            ) : (
              <DynamicGrid
                myGrid={props.myGrid}
                bodyData={props.data}
                attributes={props.attributes}
                appId={appId}
                relationshipId={props.relationshipId}
                toggleData={props.toggleSelect}
                recordClick={props.toggleSelect}
                stateField={props.stateFieldVal}
                relnRef={props.relnRef}
                component="externalTypeView"
                relationTable={true}
                dateFormat={props.dateFormat}
                dateTimeFormat={props.dateTimeFormat}
                groupByAttribute={props.groupByAttribute}
                showAggregate={props.showAggregate}
                aggregateParameters={props.aggregateParameters}
                aggregatePosition={props.aggregatePosition}
                showSorting={props.showSorting}
                sortingParameters={props.sortingParameters}
                selectedRows={props.selectedRows}
                setSelectedRows={props.setSelectedRows}
              />
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};
export default Result;
